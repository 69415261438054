import Link from 'next/link';
import { usePathname, useSearchParams } from 'next/navigation';

type Props = {
  children: React.ReactNode;
  href: string;
  variant?: 'primary' | 'secondary';
  onClick?: () => void;
  preservePathParams?: boolean;
  preserveQueryParams?: boolean;
};

const NavLink = ({
  children,
  href,
  onClick,
  preservePathParams,
  preserveQueryParams,
  variant = 'primary',
}: Props) => {
  const currPathname = usePathname();
  const searchParams = useSearchParams();
  let pathname = preservePathParams ? currPathname + href : href;
  searchParams?.forEach((value, key) => {
    if (preserveQueryParams) {
      pathname += `${pathname.includes('?') ? '&' : '?'}${key}=${value}`;
    }
  });
  const active = currPathname && href.endsWith(currPathname);
  const activeStyles =
    variant === 'primary'
      ? 'text-brand-black underline'
      : 'text-brand-primary font-semibold';
  return (
    <Link
      className={`py-[0.8rem] font-medium  transition-colors hover:text-brand-primary ${
        active ? activeStyles : 'text-inherit'
      }`}
      href={pathname}
      onClick={() => onClick?.()}
      prefetch={false}
    >
      {children}
    </Link>
  );
};

export default NavLink;

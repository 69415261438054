import React from 'react';

const Boxes = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 19L8.03 21.38C7.71894 21.5669 7.36289 21.6656 7 21.6656C6.63711 21.6656 6.28106 21.5669 5.97 21.38L2.97 19.58C2.67476 19.4026 2.43033 19.152 2.26039 18.8524C2.09045 18.5528 2.00075 18.2144 2 17.87V14.63C2.00075 14.2856 2.09045 13.9472 2.26039 13.6476C2.43033 13.348 2.67476 13.0974 2.97 12.92L7 10.5L12 13.5M12 19V13.5M12 19L15.97 21.38C16.2811 21.5669 16.6371 21.6656 17 21.6656C17.3629 21.6656 17.7189 21.5669 18.03 21.38L21.03 19.58C21.3252 19.4026 21.5697 19.152 21.7396 18.8524C21.9096 18.5528 21.9992 18.2144 22 17.87V14.63C21.9992 14.2856 21.9096 13.9472 21.7396 13.6476C21.5697 13.348 21.3252 13.0974 21.03 12.92L17 10.5L12 13.5M12 13.5L7 16.5M12 13.5L17 16.5M7 16.5L2.26 13.65M7 16.5V21.67M17 16.5L21.74 13.65M17 16.5V21.67"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13.5016L7 10.5016V6.13156C7.00075 5.78713 7.09045 5.44873 7.26039 5.14915C7.43033 4.84956 7.67476 4.59894 7.97 4.42156L10.97 2.62156C11.2811 2.43467 11.6371 2.33594 12 2.33594C12.3629 2.33594 12.7189 2.43467 13.03 2.62156L16.03 4.42156C16.3252 4.59894 16.5697 4.84956 16.7396 5.14915C16.9096 5.44873 16.9992 5.78713 17 6.13156V10.5016L12 13.5016ZM12 13.5016V8.00156M12 8.00156L7.26 5.15156M12 8.00156L16.74 5.15156"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Boxes;

import Accordion from '@ui/Accordion';
import NavLink from '@ui/Link/NavLink';
import React from 'react';

const ShopMenu = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <MenuSubheading text="SHOP" />
      <div className="pl-[1.6rem]">
        <Accordion
          arrowLeft
          title={'Riding Gear'}
          highlightHeaderOnOpen
          noBoldTitle
          titleHref="/shop/riding-gear"
          onTitleClick={() => {
            onClick();
          }}
        >
          <div className="flex flex-col  px-[1.6rem]">
            <NavLink
              href={'/shop/riding-gear/apparel'}
              onClick={() => {
                onClick();
              }}
              key={'apparel'}
            >
              Apparel
            </NavLink>
            <NavLink
              href={'/shop/riding-gear/boots'}
              onClick={() => {
                onClick();
              }}
              key={'boots'}
            >
              Boots
            </NavLink>
            <NavLink
              href={'/shop/riding-gear/helmets'}
              onClick={() => {
                onClick();
              }}
              key={'helmets'}
            >
              Helmets
            </NavLink>
            <Accordion
              title={'Gear'}
              intent="fullWidth"
              titleHref="/shop/riding-gear/gear"
              arrowLeft
              noBoldTitle
              onTitleClick={onClick}
            >
              <div className="flex flex-col  px-[1.6rem]">
                <NavLink
                  href={'/shop/riding-gear/gear/gear-combo'}
                  onClick={() => {
                    onClick();
                  }}
                  key={'helmets'}
                >
                  Gear Combo
                </NavLink>
                <NavLink
                  href={'/shop/riding-gear/gear/jerseys'}
                  onClick={() => {
                    onClick();
                  }}
                  key={'jerseys'}
                >
                  Jerseys
                </NavLink>
                <NavLink
                  href={'/shop/riding-gear/gear/pants'}
                  onClick={() => {
                    onClick();
                  }}
                  key={'pants'}
                >
                  Pants
                </NavLink>
                <NavLink
                  href={'/shop/riding-gear/gear/gloves'}
                  onClick={() => {
                    onClick();
                  }}
                  key={'gloves'}
                >
                  Gloves
                </NavLink>
              </div>
            </Accordion>

            <NavLink
              href={'/shop/riding-gear/goggles'}
              onClick={() => {
                onClick();
              }}
              key={'goggles'}
            >
              Goggles
            </NavLink>
            <NavLink
              href={'/shop/riding-gear/protective'}
              onClick={() => {
                onClick();
              }}
              key={'protective'}
            >
              Protective
            </NavLink>
          </div>
        </Accordion>
        <Accordion
          arrowLeft
          title={'Bike Parts'}
          noBoldTitle
          titleHref="/shop/dirt-bike-parts"
          onTitleClick={() => {
            onClick();
          }}
        >
          <div className="flex flex-col  px-[1.6rem]">
            <NavLink
              href="/shop/dirt-bike-parts/bars-controls"
              onClick={() => {
                onClick();
              }}
              key={'bars-controls'}
            >
              Bars & Controls
            </NavLink>
            <NavLink
              href="/shop/dirt-bike-parts/body-parts-accessories"
              onClick={() => {
                onClick();
              }}
              key={'body-parts-accessories'}
            >
              Body Parts & Accessories
            </NavLink>
            <NavLink
              href="/shop/dirt-bike-parts/brakes"
              onClick={() => {
                onClick();
              }}
              key={'brakes'}
            >
              Brakes
            </NavLink>
            <NavLink
              href="/shop/dirt-bike-parts/drive"
              onClick={() => {
                onClick();
              }}
              key={'drive'}
            >
              Drive
            </NavLink>
            <NavLink
              href="/shop/dirt-bike-parts/engine"
              onClick={() => {
                onClick();
              }}
              key={'engine'}
            >
              Engine
            </NavLink>
            <NavLink
              href="/shop/dirt-bike-parts/exhaust"
              onClick={() => {
                onClick();
              }}
              key={'exhaust'}
            >
              Exhaust
            </NavLink>
            <NavLink
              href="/shop/dirt-bike-parts/fluids-lubrication"
              onClick={() => {
                onClick();
              }}
              key={'fluids-lubrication'}
            >
              Fluids & Lubrication
            </NavLink>
            <NavLink
              href="/shop/dirt-bike-parts/fuel-system"
              onClick={() => {
                onClick();
              }}
              key={'fuel-system'}
            >
              Fuel System
            </NavLink>
            <NavLink
              href="/shop/dirt-bike-parts/intake"
              onClick={() => {
                onClick();
              }}
              key={'intake'}
            >
              Intake
            </NavLink>
            <NavLink
              href="/shop/dirt-bike-parts/lights-electrical"
              onClick={() => {
                onClick();
              }}
              key={'lights-electrical'}
            >
              Lights & Electrical
            </NavLink>
            <NavLink
              href="/shop/dirt-bike-parts/suspension"
              onClick={() => {
                onClick();
              }}
              key={'suspension'}
            >
              Suspension
            </NavLink>
            <NavLink
              href="/shop/dirt-bike-parts/wheels"
              onClick={() => {
                onClick();
              }}
              key={'wheels'}
            >
              Wheels
            </NavLink>
          </div>
        </Accordion>
        <Accordion
          title={'Dirt Bikes'}
          titleHref="/dirt-bikes-for-sale"
          noBoldTitle
          arrowLeft
          onTitleClick={() => {
            onClick();
          }}
        >
          <div className="flex flex-col px-[1.6rem]">
            <NavLink
              onClick={() => {
                onClick();
              }}
              href="/beta-dirt-bikes-for-sale"
              key={'beta'}
            >
              Beta
            </NavLink>
            <NavLink
              onClick={() => {
                onClick();
              }}
              href="/cobra-dirt-bikes-for-sale"
              key={'cobra'}
            >
              Cobra
            </NavLink>
            <NavLink
              onClick={() => {
                onClick();
              }}
              href="/gas-gas-dirt-bikes-for-sale"
              key={'gas'}
            >
              Gas Gas
            </NavLink>
            <NavLink
              onClick={() => {
                onClick();
              }}
              href="/honda-dirt-bikes-for-sale"
              key={'honda'}
            >
              Honda
            </NavLink>
            <NavLink
              onClick={() => {
                onClick();
              }}
              href="/husqvarna-dirt-bikes-for-sale/"
              key={'husqvarna'}
            >
              Husqvarna
            </NavLink>
            <NavLink
              onClick={() => {
                onClick();
              }}
              href="/ktm-dirt-bikes-for-sale/"
              key={'ktm'}
            >
              KTM
            </NavLink>
            <NavLink
              onClick={() => {
                onClick();
              }}
              href="/kawasaki-dirt-bikes-for-sale/"
              key={'kawasaki'}
            >
              Kawasaki
            </NavLink>
            <NavLink
              onClick={() => {
                onClick();
              }}
              href="/sherco-dirt-bikes-for-sale/"
              key={'sherco'}
            >
              Sherco
            </NavLink>
            <NavLink
              onClick={() => {
                onClick();
              }}
              href="/suzuki-dirt-bikes-for-sale/"
              key={'suzuki'}
            >
              Suzuki
            </NavLink>
            <NavLink
              onClick={() => {
                onClick();
              }}
              href="/yamaha-dirt-bikes-for-sale/"
              key={'yamaha'}
            >
              Yamaha
            </NavLink>
          </div>
        </Accordion>
        <Accordion
          arrowLeft
          title={'Mountain Bikes'}
          noBoldTitle
          titleHref="/shop/mountain-bikes"
          onTitleClick={() => {
            onClick();
          }}
        >
          <div className="flex flex-col  px-[1.6rem]">
            <NavLink
              href="/shop/mountain-bikes/accessories"
              onClick={() => {
                onClick();
              }}
              key={'accessories'}
            >
              Accessories
            </NavLink>
            <NavLink
              href="/shop/mountain-bikes/gear"
              onClick={() => {
                onClick();
              }}
              key={'gear'}
            >
              Gear
            </NavLink>
            <NavLink
              href="/shop/mountain-bikes/parts"
              onClick={() => {
                onClick();
              }}
              key={'parts'}
            >
              Parts
            </NavLink>
            <NavLink
              href="/shop/mountain-bikes/bikes"
              onClick={() => {
                onClick();
              }}
              key={'bikes'}
            >
              Bikes
            </NavLink>
          </div>
        </Accordion>
      </div>
    </>
  );
};

export const MenuSubheading = ({ text }: { text: string }) => (
  <div className="flex flex-col p-[1.2rem]">
    <p className=" font-normal text-black">{text}</p>
  </div>
);

export default ShopMenu;
